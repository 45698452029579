const state = () => ({
  trackingAuthorized: 'undecided'
})

const getters = {
  getTrackingAuthorized(state){
    return state.trackingAuthorized
  }
}

const actions = {
  // payload needs to be a string
  async setTrackingAuthorized({ commit }, payload){

    commit('saveTrackingAuthorized', payload)
  },
  async loadTrackingAuthorized({ commit }) {

  }
}

const mutations = {
  saveTrackingAuthorized(state, status){
    state.trackingAuthorized = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
