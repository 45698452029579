<template>

</template>

<script>

export default {
  name: 'OBT',
  data () {
    return {
      
    }
  },
  watch:{
  },
  computed: {
  },

  async created () {
  },

  async mounted () {}
}
</script>
