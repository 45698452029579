import { createRouter, createWebHistory } from '@ionic/vue-router'
import { Device } from '@capacitor/device'
import { isPlatform } from '@ionic/vue'
import store from '../store/index'

// SPECIFIC FOR PWA >>>

let isInvite = window.location.pathname.includes('invite')
if (isInvite) {
  let searchParams = window.location.search // gives '/user_id'
  if (searchParams && searchParams.length > 0) {
    let userId = window.location.search.substring(1)
    store.dispatch('auth/recommendedByUser', {
      data: {
        User: userId
      }
    }).then(
      () => {
        console.log("success")
        window.location.assign('https://app.orszagosbortura.hu')
      },
      (error) => {
        console.log('error: ' + error)
        // window.location.assign('http://localhost:8100')
        window.location.assign('https://app.orszagosbortura.hu')
      }
    )
  }
}

let openPage = false
if(window.location.pathname == '/email-ok' || window.location.pathname == '/ujjelszo' || window.location.pathname == '/app/udvozoljuk'){
  openPage = true
}

let nativePlatform = false

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

// check platform for mobile non tablet
// only if we're not on openPage
if (isPlatform('mobile') && !isPlatform('tablet') && !openPage) {
  checkDevice()
// exclude tablets
} else {
  window.location.href = 'https://www.orszagosbortura.hu'
  sleep(1000)
}

async function checkDevice() {
  let device;
  device = await Device.getInfo()
  // alert(JSON.stringify(device))
  // ios
  if(device.operatingSystem == 'ios') {
    nativePlatform = true
    window.location.href = 'https://apps.apple.com/us/app/orszagos-bortura/id1588683275'
    sleep(1000)
  // android
  } else if (device.operatingSystem == 'android') {
    nativePlatform = true
    window.location.href = 'https://play.google.com/store/apps/details?id=hu.orszagosbortura.bortura'
    sleep(1000)
  } else {
    window.location.href = 'https://www.orszagosbortura.hu'
    sleep(1000)
  }

}
// <<< SPECIFIC FOR PWA

const routes = [
  {
    path: '/',
    component: ()=>import('@/pages/App.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

async function guard(to, from, next) {
  // let device;
  // if (!device) {
  //   device = await Device.getInfo()
  // }
  // console.log(device)
  // if (to.fullPath == '/platformerror') {
  //   window.location.href = 'https://www.orszagosbortura.hu'
  //   sleep(1000)
  //   // return next()
  // // exclude non-ios or non-android devices
  // } else if (device.operatingSystem != 'ios' && device.operatingSystem != 'android') {
  // // } else if (device.operatingSystem != 'ios' && device.operatingSystem != 'android' && device.operatingSystem != 'mac') {
  //   // return next({ path: '/platformerror' })
  //   window.location.href = 'https://www.orszagosbortura.hu'
  //   sleep(1000)
  // } else if (nativePlatform) {
  //   window.location.href = 'https://www.orszagosbortura.hu'
  //   sleep(1000)
  // } else if (to.fullPath == '/') {
  //   if (authData && authData.userId) {
  //     window.location.href = 'https://www.orszagosbortura.hu'
  //     sleep(1000)
  //   } else {
  //     window.location.href = 'https://www.orszagosbortura.hu'
  //     sleep(1000)
  //   }
  // } else if (to.meta && to.meta.requiredAuth) {
  //   window.location.href = 'https://www.orszagosbortura.hu'
  //   sleep(1000)
  // } else {
  //   window.location.href = 'https://www.orszagosbortura.hu'
  //   sleep(1000)
  // }
  
}

router.beforeEach((to, from) => {
  return guard(to, from)
})

export default router
