<template>
  <ion-page>
    <ion-content
      :class="bgColor"
    >
      <slot> </slot>
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonPage, IonContent
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import { arrowBack } from 'ionicons/icons'

export default {
  components: {
    IonPage, IonContent
  },
  props: [
    'bgColor',
    'headerVisible'
  ],
  created() {
    addIcons({
      'arrow-back': arrowBack
    })
  }
}
</script>

<style>
ion-content{
  --background: #f6dbcd;
  --color: #5057a0;
  --padding-top: env(safe-area-inset-top);
  --padding-bottom: env(safe-area-inset-bottom);
}
ion-content.white{
  --background: #ffffff;
}
ion-content.transparent{
  --background: transparent;
}
ion-content.intro{
  --background: #282433;
}
ion-toolbar{
  --background: #f6dbcd;
}
ion-toolbar.white{
  --background: #ffffff;
}
</style>
