const state = () => ({
  myWineriesChanged: false,
  myWineriesZero: null, // default setting at the start, used in Boraszatok
  wineryInMemory: null,
  ratingInMemory: null,
  fromPage: null
})

const getters = {
  getMyWineriesChanged(state){
    return state.myWineriesChanged
  },
  getMyWineriesZero(state){
    return state.myWineriesZero
  },
  getWineryInMemory(state){
    return state.wineryInMemory
  },
  getRatingInMemory(state){
    return state.ratingInMemory
  },
  getFromPage(state){
    return state.fromPage
  }
}

const actions = {
  setMyWineriesChanged({ commit }, payload){
    commit('saveMyWineriesChanged', payload)
  },
  setMyWineriesZero({ commit }, payload){
    commit('saveMyWineriesZero', payload)
  },
  setWineryInMemory({ commit }, payload){
    commit('saveWineryInMemory', payload)
  },
  setRatingInMemory({ commit }, payload){
    commit('saveRatingInMemory', payload)
  },
  setFromPage({ commit }, payload){
    commit('saveFromPage', payload)
  }
}

const mutations = {
  saveMyWineriesChanged(state, status){
    state.myWineriesChanged = status
  },
  saveMyWineriesZero(state, status){
    state.myWineriesZero = status
  },
  saveWineryInMemory(state, status){
    state.wineryInMemory = status
  },
  saveRatingInMemory(state, status){
    state.ratingInMemory = status
  },
  saveFromPage(state, status){
    state.fromPage = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
