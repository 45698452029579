import axios from 'axios'

const state = () => ({
  authData: {
    token: null,
    userId: null,
    userName: null,
    firstName: null,
    lastName: null,
    email: null
  },
  loginStatus: 'failed'
})

const getters = {
  getLoginStatus(state){
    return state.loginStatus
  },
  getAuthData(state){
    return state.authData
  }
}

const actions = {
  async loginUser ({ commit }, payload) {
    try {
      const response = await axios.post(process.env.VUE_APP_STRAPI_URI + 'auth/local', payload)
      console.log('Logged in:')
      console.log(response.data)

      
      const tokenData = {
        token: response.data.jwt,
        userId: response.data.user.id,
        userName: response.data.user.username,
        firstName: response.data.user.FirstName,
        lastName: response.data.user.LastName,
        email: response.data.user.email
      }
      commit('saveAuthToken', tokenData)
      commit('saveLoginStatus', 'success')
      console.log(commit)
   } catch (error) {
     console.log('An error occurred:', error?.response?.data.error)
     commit('saveLoginStatus', 'failed')
   }
  },

  async registerUser({ commit }, payload){
    try {
     const response = await axios.post(process.env.VUE_APP_STRAPI_URI + 'auth/local/register', payload)
       console.log(response.data)

       
       const tokenData = {
         token: response.data.jwt,
         userId: response.data.user.id,
         userName: response.data.user.username,
         firstName: response.data.user.FirstName,
         lastName: response.data.user.LastName,
         email: response.data.user.email
       }
       commit('saveAuthToken', tokenData)
       commit('saveLoginStatus', 'success')
       console.log(commit)
    } catch (error) {
      // console.log('An error occurred:', error?.response?.data.error)
      if(error.response && error?.response?.data && error?.response?.data.message && error?.response?.data.message[0] && error?.response?.data.message[0].messages[0] && error?.response?.data.message[0].messages[0].message){
        commit('saveLoginStatus', error?.response?.data.message[0].messages[0].message)
      } else {
        commit('saveLoginStatus', 'some other error message')
      }
    }
  },

  async logoutUser({ commit }) {
    
    const tokenData = {
     token: null,
     userId: null,
     userName: null,
     firstName: null,
     lastName: null,
     email: null
    }
    commit('saveAuthToken', tokenData)
    commit('saveLoginStatus', 'failed')
  },

  async loadStorageTokens({ commit }) {
   
  },

  async recommendedByUser ({ commit }, payload) {
    try {
      const response = await axios.post(process.env.VUE_APP_STRAPI_URI + '/api/obt-recommended-by-users', payload)
      console.log(response)
    } catch (error) {
      console.log('An error occurred:', error?.response?.data.error)
    }
    console.log(commit)
  }
}

const mutations = {
  saveAuthToken(state, payload) {
    const newAuthData = {
      token: payload.token,
      userId: payload.userId,
      userName: payload.userName,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email
    }
    state.authData = newAuthData
  },
  saveLoginStatus(state, status){
    state.loginStatus = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
