import {createStore} from 'vuex'
import AuthModule from './modules/auth'
import WineriesModule from './modules/wineries'
import WineriesApiModule from './modules/wineriesapi'
import PrivacyModule from './modules/privacy'

const store = createStore({
    modules:{
        auth:AuthModule,
        wineries:WineriesModule,
        wineriesapi:WineriesApiModule,
        privacy:PrivacyModule
    }
})

export default store
